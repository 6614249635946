$(() => {
    InitUnsubscribeForm();
})

const InitUnsubscribeForm = () => {
    const searchParams = new URLSearchParams(window.location.search)
    
    if (searchParams.has("unsubscribeEmail")) {
        let email = searchParams.get("unsubscribeEmail")
        submitForm(email);
    }
    
    $("#newsletter-unsubscribe-submit").off('click').on('click', () => {
        const email = $("#newsletter-unsubscribe-email").val();

        submitForm(email);
    })   
}

const submitForm = (email) => {
    $.ajax({
        dataType: 'json',
        url: '/newslettersubscription/unsubscribe?unsubscribeEmail=' + email,
        type: 'POST'
    }).done(function (data) {
        if (data.success) {
            $("#newsletter-unsubscribe-success").show();
            $("#newsletter-unsubscribe-form").hide();
        }
    })
}